import { IUser } from "../../models/user/user.model";
import { createReducer, on } from "@ngrx/store";
import * as UserActions from '../actions/user.actions';

export const userFeatureKey = 'USER_STATE_KEY';

export interface UserState {
  user: IUser | null
}

export const initialState: UserState = {
  user: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.setCurrentUser, (state, { user }) => ({ ...state, user })),
);
